table {
  border-collapse: collapse;
  width: 100%;
}

.tdNameEntity {
  text-align: left;
  padding: 18px;
  height: 40px;
  /* color: rgb(255, 255, 255); */
}
.tdData {
  text-align: center;
  padding: 8px;
  height: 40px;
}

td {
  font-size: 18px;
  border: 1em;
  border-color: black;
  /* color: rgb(219, 219, 219); */
}

.colorError {
  background-color: rgba(168, 0, 0, 0.507);
}

/* tr:nth-child(even) {
  background-color: #6d6d6d;
} */

.card :hover {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.app {
  display: flex;
  height: 100%;
}

.part1 {
  flex: 1;
  overflow-y: scroll;
  /* scrollbar-width: 1px; */
  /* height: 100%; */
}

.part2 {
  flex: 4;
}

.part2-select {
  flex: 1;
  /* background-color: #3d3d3d; */
  margin: 0.2em;

}
.part2-display {
  flex: 3;
  /* background-color: #3d3d3d; */
  margin: 0.2em;
  display: flex;

  height: 89.6%;
}

.part2-select-view {
  flex: 1;
  display: flex;
}
.part2-select-view-name {
  flex: 1;
  margin: 0.1em;

  border: 0.1em;
  border-style: solid;
  border-color: grey;

  font-size: 24px;
}
.part2-select-view-type {
  flex: 1;
  margin: 0.1em;


  border: 0.1em;
  border-style: solid;
  border-color: grey;

  font-size: 24px;
}
.part2-select-view-showmore {
  flex: 1;
  /* background-color: #464646; */
  margin: 0.3em;

  font-size: 24px;
}

.part2-select-date {
  flex: 1;
  /* background-color: rgb(173, 173, 173); */
  margin: 0.1em;

  font-size: 18px;


  border: 0.1em;
  border-style: solid;
  border-color: grey;
}

.part2-display-save {
  flex: 1;
  /* background-color: #3d3d3d; */
  margin: 0.2em;

  overflow-y: scroll;
}

.part2-display-data {
  flex: 3;
  /* background-color: #3d3d3d; */
  margin: 0.2em;

  /* background-color: rgb(88, 88, 88); */
}
.part2-display-data {
  overflow-y: scroll;
}

@media (prefers-color-scheme: dark) {
  tr:nth-child(even) {
    background-color: #6d6d6d;
  }

  /* .part2-select-view-name {
    border: 0.1em;
    border-style: solid;
    border-color: grey;
  }
  .part2-select-view-type {
    border: 0.1em;
    border-style: solid;
    border-color: grey;
  } */
}
@media (prefers-color-scheme: light) {
  tr:nth-child(even) {
    background-color: #dedede;
  }

  /* .part2-select-view-name {
    border: 0.1em;
    border-style: solid;
    border-color: grey;
  }
  .part2-select-view-type {
    border: 0.1em;
    border-style: solid;
    border-color: grey;
  } */
}
