/* 

app
 ├── part1-count
 │     ├── part1-count-recup
 │     └── part1-count-societe
 │
 └── part2-display
       ├── part2-display-recup
       └── part2-display-societe

*/


.appCheck {
  display: flex;
  flex-direction: column;
}

.part1-count {
  display: flex;
  flex-direction: row;
}
.part1-count-recup {
  flex: 1 1 auto;
}
.part1-count-societe {
  flex: 1 1 auto;
}

.part2-display {
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: row;
}
.part2-display-recup {
  /* overflow-y: scroll; */
  width: 50%;
  flex: 1;
}
.part2-display-societe {
  /* overflow-y: scroll; */
  width: 50%;
  flex: 1;
}

.card-position {
  display: flex;
}

.card-middle {
  flex: 9;
}

.card-right {
  flex: 1;
}

.test {
  /* background-color: aqua; */

  /* overflow-y: scroll; */

  margin: 0.8em;
}

.colorError {
  background-color: rgba(168, 0, 0, 0.507);
}
.colorError:hover {
  background-color: rgba(193, 0, 0, 0.798);
}

.colorWarning {
  background-color: rgba(244, 163, 0, 0.593);
}
.colorWarning:hover {
  background-color: rgba(244, 163, 0, 0.771);
}

.colorPrevent {
  background-color: rgba(190, 190, 0, 0.812);
}
.colorPrevent:hover {
  background-color: rgba(162, 162, 0, 0.812);
}

.colorGood {
  background-color: rgba(116, 169, 0, 0.833);
}
.colorGood:hover {
  background-color: rgba(98, 144, 0, 0.833);
}

.error {
  background-color: rgba(255, 255, 255, 0.62);
}
.error:hover {
  background-color: rgba(255, 255, 255, 0.867);
}

.card_status {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}