.selector_home{
    /* background:red; */
    height:100%;

    display: flex;
    flex-direction: column;
}

    .selector_group_title{
        flex: 1;
    }
        .selector_title{
            flex: 1;
            margin: 15px;
            font-size: 80px;
            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        }

    .selector_group_log{
        flex: 1;
    }
        .selector_name_log{
            display: flex;
            flex-direction: row;
        }
            .selector_log_access_title{
                flex: 1;
                font-size: 20px;
            }
            .selector_log_error_title{
                flex: 1;
                font-size: 20px;
            }
        .selector_show_log{
            display: flex;
            flex-direction: row;
        }
            .selector_log_access{
                flex: 1;
            }
            .selector_log_error{
                flex: 1;
            }
    
    .selector_group_button{
        flex: 3;
    }
        .selector_buttons{
            height:100%;
            margin: 10px;

            display: flex;
            flex-direction: row;
        }
            .selector_div_button{
                flex: 1;

                margin: 10px;
            
                transition: all .2s ease-in-out;
            }
            .selector_div_button:hover{
                flex: 2;

                transition: 0.2s;
            }


.selector_button{
    height: 80%;
    width: 100%;
    border-style: solid;
    border-width: 3px;

    transition: all .4s ease-in-out;
}
.selector_button:hover{
    background-color: rgb(101, 101, 101);

    transition: 0.4s;
}

.card_title{
    font-size: 50px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.card_content{
    font-size: 30px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}