.app {
    /* display: flex; */
}

.part1 {
    flex: 1;
}

.part2 {
    flex: 4;
    /* overflow-x: scroll; */
}

.part2-thead {
    font-size: 10px;
    size: 100px;
}

.part2-td {
    font-size: large;
    width: 20em;

    
    max-height: 150px;
    /* overflow-y: scroll; */
    overflow: auto;
}

@media (prefers-color-scheme: dark) {
    thead,
    tfoot {
        background-color: #333;
        color: #fff;
    }
    table,
    td {
        border: 1px solid rgb(58, 58, 58);
    }
    tr:nth-child(even) {
        color: #eeeeee;
    }
}

@media (prefers-color-scheme: light) {
    table,
    td {
        border: 1px solid rgb(199, 199, 199);
    }
    tr:nth-child(even) {
        color: rgb(56, 56, 56);
    }
}

.circle {
    /* margin: 100px auto 0; */
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: crimson;
    border-bottom-color: crimson;
    animation: spin 1s linear infinite;

    float: right;
}

@keyframes spin {
    to {
        transform: rotate(360deg)
    }
}